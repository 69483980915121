@status-bar-width: 6px;
@status-icon-size: 18px;
@status-color-skip: #3d4749;
@status-color-wait: #afaeaa;
@status-color-pending: #ecaa54;
@status-color-confirming: #804eef;
@status-color-running: #3cadf8;
@status-color-success: #5cce74;
@status-color-failed: #ec3e40;


#container {
  border: 1px solid #999;
  border-radius: 4px;
  background: #f2f4f6;
}

.flow-container {
  width: 100%;
}

.node {
  display: flex;
  align-items: center;
  width: 240px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #c2c8d5;
  border-left: 4px solid #5F95FF;
  border-radius: 4px;
  //box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
}


.node img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 8px;
}

.node .info {
  display: inline-block;
  flex-shrink: 0;
  width: 100%;
  margin-left: 8px;
  color: #666;
  font-size: 14px;
}

.node .title {
  padding-right: 18px;
  overflow:hidden; //超出的文本隐藏
  text-overflow:ellipsis; //溢出用省略号显示
  white-space:nowrap; //溢出不换行
}

.node .description {
  padding-right: 18px;
  overflow:hidden; //超出的文本隐藏
  text-overflow:ellipsis; //溢出用省略号显示
  white-space:nowrap; //溢出不换行
}

.node .option-tag-group {
  margin-top: 4px;
}

.node .option-tag-group .option-tag {
  border-radius: 4px;
  font-size: 12px;
  padding: 0 4px;
  background: #dceee4;
  width: 60px;
}

.node .option-tag-group .option-tag.enabled {
  background: #00b27f;
  color: #fff;
}

.node .option-tag-group .option-tag.disabled {
  background: #a8a8a8;
  color: #e8e8e8;
}

.node .status {
  flex-shrink: 0;
}

.node.primary {
  border-left: @status-bar-width solid #3F51B5;
  color: #3F51B5;
  font-size: @status-icon-size;
}

.node.Skipped {
  border-left: @status-bar-width solid @status-color-skip;
  color: @status-color-skip;
  font-size: @status-icon-size;
  background: rgba(0, 0, 0, 0.05);
}

.node.Waiting {
  border-left: @status-bar-width solid @status-color-wait;
  color: @status-color-wait;
  font-size: @status-icon-size;
}

.node.Pending {
  border-left: @status-bar-width solid @status-color-pending;
  color: @status-color-pending;
  font-size: @status-icon-size;
}

.node.Confirming {
  border-left: @status-bar-width solid @status-color-confirming;
  color: @status-color-confirming;
  font-size: @status-icon-size;
}

.node.Running {
  border-left: @status-bar-width solid @status-color-running;
  color: @status-color-running;
  font-size: @status-icon-size;
  //animation: spin 1s linear infinite;
}

.node.Succeeded {
  border-left: @status-bar-width solid @status-color-success;
  color: @status-color-success;
  font-size: @status-icon-size;
}

.node.Failed {
  border-left: @status-bar-width solid @status-color-failed;
  color: @status-color-failed;
  font-size: @status-icon-size;
}


/* 有关选择之后的样式 */
.x6-node-selected .node {
  border-radius: 8px;
  box-shadow: 0 0 0 4px #d3dbe1;
}

.x6-node-selected .node.skip {
  border-color: @status-color-skip;
  border-radius: 8px;
  box-shadow: 0 0 0 4px #d9d9d9;
}

.x6-node-selected .node.wait {
  border-color: @status-color-wait;
  border-radius: 2px;
  box-shadow: 0 0 0 4px #ece0ce;
}

.x6-node-selected .node.running {
  border-color: @status-color-running;
  border-radius: 2px;
  box-shadow: 0 0 0 4px #c0dfea;
}

.x6-node-selected .node.success {
  border-color: @status-color-success;
  border-radius: 2px;
  box-shadow: 0 0 0 4px #ccecc0;
}

.x6-node-selected .node.failed {
  border-color: @status-color-failed;
  border-radius: 2px;
  box-shadow: 0 0 0 4px #fedcdc;
}

/*线被触摸的时候的样式改变 */

/* 按理说这些样式应该是固定的,因为几乎想要改变样式的时候都要这么做,他把一些原来需要了解api才能实现的方法做成了仅仅需要操作css就能实现效果的操作了 */
.x6-edge:hover path:nth-child(2) {
  stroke: #cea01a;
  stroke-width: 4px;
}

.x6-edge-selected path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 4px !important;
}

@keyframes running-line {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
