@keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.2;
    }
    to {
        opacity: 1.0;
    }
}
@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.2;
    }
    to {
        opacity: 1.0;
    }
}

.status-blink {
    animation: fade 2s infinite;
    -webkit-animation: fade 2s infinite;
}

.status-blink.fast {
    animation: fade 1s infinite;
    -webkit-animation: fade 1s infinite;
}

.status-blink.slow {
    animation: fade 3s infinite;
    -webkit-animation: fade 3s infinite;
}
